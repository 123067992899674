import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import PortableText from "../components/portableText";

export const query = graphql`
  query EducatePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawEducatePageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const EducatePage = ({ data = {} }) => {
  const educatePageContent = data.site?._rawEducatePageContent;
  return (
    <>
      <SEO title="Educate Against Hate" />
      {educatePageContent && (
        <div className="prose dark:prose-invert prose-lg md:py-12">
          <PortableText blocks={educatePageContent} />
        </div>
      )}
    </>
  );
};

export default EducatePage;
